import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../Components/Layout'

import './project-page.scss'

const ProjectPage = ({ data }) => {

  const { title, mainImage, mainAlt, bonusImage, bonusAlt, link, linkText, altLink, altLinkText, credit } = data.mdx.frontmatter
  const body = data.mdx.body 

  return (
    <Layout>
      <article className='project-page'>
        <div className='project-page-title'>
          <h3>{title}</h3>
          <GatsbyImage 
            image={getImage(mainImage)}
            alt={mainAlt}  
          />
        </div>
        <div className="project-body">
          <MDXRenderer>
            {body}
          </MDXRenderer>
        </div>
        {bonusImage && 
          <GatsbyImage 
            image={getImage(bonusImage)}
            alt={bonusAlt}  
          />
        }
        <a 
          href={link}
          className="project-page-link" 
          target={"_blank"}
          rel="noopener noreferrer"
        >{linkText}</a>
        <a 
          href={altLink}
          className="project-page-link" 
          target={"_blank"}
          rel="noopener noreferrer"
        >{altLinkText}</a>
        <p className='credit'>{credit}</p>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        link
        linkText
        altLink
        altLinkText
        credit
        mainAlt
        mainImage {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
            )
          }
        }
        bonusAlt
        bonusImage {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
            )
          }
        }
      }
      body
    }
  }
`

export default ProjectPage
